import Layout from "../../components/shared/Layout";

import React from "react";

export default function Portfolio() {
  return (
    <Layout>
      <div>PORTFOLIO PAGE</div>
    </Layout>
  );
}
