import Layout from "../../components/shared/Layout";
import React from "react";

export default function Resume() {
  return (
    <Layout>
      <div>RESUME PAGE</div>
    </Layout>
  );
}
